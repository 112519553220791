import React from "react"
import Layout from "../components/layout"
import background from "../Figures/Site_Header_Background.png"

export default function About() {
  return (
    <div>
    <Layout>
    </Layout>
    <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div style={{ margin: `3rem auto`, maxWidth: 650, padding: `0 1rem`, verticalAlign: "middle", display: "table-cell" }}>
      <h1 style={{color: "white" }}>PRO001-Rotary Cellphone</h1>
        </div>
      </div>
      <div style={{ margin: `3rem auto`, maxWidth: 650 , padding: `0 1rem` }}>
        <h2> PRO001-Rotary Cell phone </h2>
      </div>
    </div>
  )
}